import React, { Component } from 'react';

import Center from './cont/Center';

import './App.css';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      place: "about"
    }

  }
  view(place){
    this.setState({place});
  }


  render() {
    const {place} = this.state;
    return (
      <div className="App">
      <div className="blur" />
	     <div className="navCont">
       {/* <div class="navBlur" /> */}
        <nav className="nav">
            <div className="navBtn" onClick={()=>this.view('chat')}>CHAT</div>
            <div className="navBtn" onClick={()=>this.view('about')}>ABOUT</div>
            <div className="navBtn" onClick={()=>this.view('resume')}>PORTFOLIO</div>
            <div className="navBtn" onClick={()=>this.view('blog')}>BLOG</div>
        </nav>
        </div>
        <div className="centered">

          <Center place={place} />

        </div>

        <div className="bot-left">
        <div className='hlCont'>
        <span className="outer" id="name">
        <h3>David Kamer</h3>
         </span>
        </div>
        <div className='hlCont'>
        <span className="outer" id="career">
        <h1>Software Architect</h1>
        </span>
        </div>

   	 </div>
      </div>

    );
  }
}

export default App;
