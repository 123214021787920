import React, { Component } from 'react';

import About from '../components/About';
import Blog from '../components/Blog';
import Chat from '../components/Chat';
import Portfolio from '../components/Portfolio';

class Center extends Component{



    render(){
        const {place} = this.props;
        return(
            <div className="centerCont">

                {place === 'home' && <div>home</div>}
                {place === 'about' && <About />}
                {place === 'blog' && <Blog />}
                {place === 'chat' && <Chat />}
                {place === 'resume' && <Portfolio />}

            </div>
        )
    }
}

export default Center;