import React, { Component } from 'react';


class Chat extends Component{
    render(){
        return(
            <h1 className='data'>Chat Service Coming Soon!</h1>
        );
    }
}

export default Chat;