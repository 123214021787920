import React, { Component } from 'react';


class Blog extends Component{

    constructor(props){
        super(props);
        this.state = {
            posts: [
                {
                    title: 'Hello!',
                    date: '2018-12-26T06:39:54.168Z',
                    data: [
                        {type: 'text', content: `Welcome to my site! I've 
                        waited a long time to create this site due to my workload,
                        but I've decided to not go "all in" on my original idea for it.`},
                        {type: 'text', content: `Although this site might not seem
                        simple in functionality and design, I originally had a much more creative idea.
                        As time goes on, I'll add a link to my experimental site. I'll probably never replace this more basic design
                        to support accessibility. `},
                        {type: 'heading', content: `Taking A Look at this Site`},
                        {type: 'text', content: `This site is mostly powered by React. I'm writing this as I build the site, but
                        by the time you're reading this, these blog posts are being returned from an API call. I've been really into
                        using Node.js with Express.js as my API lately, so it might be that. It also might be Python (something I already know
                        but don't use often in web development) or Go/Rust one of which I'll learn in the coming months. Anyway, an API call on 
                        this page is made to return JSON that is formatted by type and content into JSX...`},
                        {type: 'text', content: `The chat uses socket.io to create a room between me and you, making a private chat. I'll probably
                         find a way to make unique JWT tokens per session, but I'll update about that in a later post. I've done much similar
                          to this in the past with other sites, but I'm using this to catalogue exact implementation details in case you want 
                          to to it yourself or in case you want to review how I have done it before you consider hiring me. (Send me a message, 
                            I'm a freelancer that is always ready to help you figure it out :D ) I'm usually able to answer as I have the chat 
                            directly connected to my phone either through SMS or an experimental React Native application.`},
                        {type: 'text', content: `The center that changes based on your navigation is just a set of components using 
                        React Transition Group. I usually use CSSTransition so as to harness the power of CSS3 animations. `},
                        {type: 'text', content: `I hope you didn't get too bored reading this--I know that I can get a little in depth, 
                        but that always comes with passion that borderlines obsession (think about any sci-fi franchise or trading card game). 
                        I don't claim to know the most or be the best, but I give my all to coding. I've always been facinated with computers,
                         and coding is the best outlet I've found for that passion.`},
                        {type: 'heading', content: `Have a wonderful day!`}
                    ]
                },
                {
                    title: 'Update',
                    date: '2018-12-31T04:20:05.029Z',
                    data: [
                        {type: 'text', content: `I've decided to launch the site before adding many of the described features in the last post.
                         I've only been able to spend about an hour on this because of my work load lately and the holidays. For now just send an email 
                         to me@davidkamer.com if you have any questions.`}
                    ]

                }
            ]
        }
    }

    render(){
        const {posts} = this.state;
        
        return(
            <div className="blog">
                {posts.map((post, i)=>{
                    const {title, date, data} = post;
                    return(
                        <div className="post">
                            <h2>{title}</h2>
                            <h3>{new Date(date).toDateString()}</h3>
                            <div className="data">
                                {data.map((item, i)=>{
                                    const {type, content} = item;
                                    return(
                                        <div className="content" key={`content${i}`}>

                                            {type === 'text' && <div>{content}</div>}
                                            {type === 'heading' && <h3>{content}</h3>}

                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default Blog;