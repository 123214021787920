import React, { Component } from 'react';

class Portfolio extends Component{
    render(){
        return(
            <h1 className='data'>Portfolio information coming soon!</h1>
        );
    }
}


export default Portfolio;