import React, { Component } from 'react';


class About extends Component{
    render(){
        return(
            <div className='blog' style={{overflowY: 'hidden'}}>
                <h1 className='data'>Welcome to my site! More info coming soon!</h1>
                <div className='data'>Check out my blog in the meantime.</div>
            </div>
        )
    }
}

export default About;